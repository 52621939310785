.Drawer {
    display: flex;
    overflow: hidden;
    align-self: center;
    max-width: 30vw;
    width: 10vw;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 800px) {
    .Drawer {
        overflow: visible;
    }
}