.About {
    margin-top: 48px;
    padding-bottom: 20px;
}

.Grid {
    padding-top: 48px;
}

.Me {
    margin-top: 48px;
}